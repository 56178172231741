$breakpoint_small: 640px;
$breakpoint_medium: 1024px;
$breakpoint_large: 1280px;
$breakpoint_extra_large: 1920px;

$color-white: #fff;
$color-black: #000;
$color-primary: #272a31;
$color-muddy-waters: #bc8f58;
$color-baltic-sea: #181619;
$color-dusty-gray: #959595;
$color-shuttle-gray: #666a74;
$color-azure-radiance: #1181f1;
$color-burnt-sienna: #ef5350;
$color-seance: #9c27b0;
$color-alabaster: #f8f8f8;
$color-mandalay: #ae7d1f;
$color-gray-6: #f2f2f2;
$color-gallery: #ededed;

$color-silver: #c4c4c4;
