@import "_variables";
@import "layout";
@import "_header";
@import "_component";
@import "_drawer";
@import "login";
@import "table";
@import "modal-verify";

body {
  font-family: "Roboto", sans-serif;
}

.hide {
  display: none !important;
}

.block {
  display: block !important;
}

.center-block {
  text-align: center;
  display: block;
}

.center-margin-block {
  margin: auto;
  display: block;
}

.bottom-close-modal {
  @extend .center-block;
  color: $color-mandalay;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.full-width,
.full-width .MuiFormControl-root {
  width: 100%;
}

@for $i from 0 through 100 {
  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }
  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }
}

a.btn-export:-webkit-any-link {
  color: transparent;
  &:active,
  &:focus {
    color: transparent;
  }
}

.description-pre-line {
  white-space: pre-line;
}

.color-des-verify {
  color: #000000 !important;
}

.search-list-kyc {
  .MuiFormControlLabel-label {
    color: $color-shuttle-gray;
    font-size: 14px;
  }
}

p.value-arr {
  margin-top: 0;
  margin-bottom: 5px;
}

.box-empty-wrap {
  text-align: center;
  img {
    margin-bottom: 5px;
    margin-top: 25px;
  }
}

.col-overflow {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: 0;
  &.one-row {
    -webkit-line-clamp: 1;
  }
}

@media (max-width: 1441px) {
  .list-kyc {
    .search-container {
      padding: 40px 13px;
    }
    .search-list-kyc .MuiFormControl-root {
      width: 100%;
    }
  }
}

.grid-image {
  img {
    max-width: 270px;
  }
}

.grid-col-image {
  img {
    margin-bottom: 30px;
  }
}

div.container-legal {
  margin-top: 26px !important;
}

.box-idoc-representative {
  display: flex;
  align-items: center;
  justify-content: center;
  .text-idoc {
    margin-right: 3px;
  }
  .MuiSvgIcon-root {
    font-size: 18px;
    color: green;
    cursor: info;
  }
}
