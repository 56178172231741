.button {
  &.button-primary {
    color: $color-white;
    background-color: $color-muddy-waters;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    &:hover {
      color: $color-white;
      background-color: $color-muddy-waters;
      opacity: 0.8;
    }
  }

  &.button-gradient {
    color: $color-white;
    background: linear-gradient(129.66deg, #bb9043 0%, #dda74f 64.36%, #bb9043 132.87%);

    &:hover {
      color: $color-white;
      background: linear-gradient(129.66deg, #bb9043 0%, #dda74f 64.36%, #bb9043 132.87%);
      opacity: 0.8;
      transition: all ease 0.3s;
    }

    &.Mui-disabled {
      color: $color-white;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &.button-logout {
    color: $color-white;
    background-color: $color-muddy-waters;
    border-radius: 0;
    font-size: 14px;
    padding: 11.5px;

    &:hover {
      color: $color-white;
      background-color: $color-muddy-waters;
      opacity: 0.8;
    }
  }

  &.button-previous {
    background-color: $color-dusty-gray;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    color: $color-white;

    &:hover {
      color: $color-white;
      background-color: $color-dusty-gray;
      opacity: 0.8;
    }
  }

  &.button-transparent {
    background-color: transparent;
    color: $color-muddy-waters;

    &:hover {
      background-color: transparent;
      color: $color-muddy-waters;
      opacity: 0.8;
    }
  }

  &.button-transparent-border {
    background-color: transparent;
    color: $color-muddy-waters;
    border: 1px solid $color-muddy-waters;

    &:hover {
      background-color: transparent;
      color: $color-muddy-waters;
      opacity: 0.8;
    }

    &.Mui-disabled {
      color: $color-muddy-waters;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.container {
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 0 16px;
}

.spring-modal {
  .kyc-request-form {
    padding: 0;
  }
}

.input__phone-number {
  &__prefix {
    display: flex;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.12);

    span {
      padding: 0.5em;
    }
  }
}

.MuiDialog-root {
  .MuiDialogActions-root {
    display: none;
  }
}

.MuiInputBase-root.Mui-disabled {
  background-color: $color-gallery;
}

label.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 1440px) {
  button.MuiIconButton-root {
    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }
  }
}
