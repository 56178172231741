.layout {
  &--public {
  }
}

.layout {
  &__container-filter-right {
    padding-right: 16px;
  }
}
