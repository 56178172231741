.login {
  display: flex;
  align-items: center;
  min-height: 100vh;

  .form {
    padding: 60px;
    text-align: center;
    background-color: $color-white;
    border: 1px solid $color-silver;

    .form {
      &__title {
        margin: 30px 0 40px;
      }
      &__input {
        margin-top: 18px;
      }

      &__link {
        display: block;
        margin-top: 16px;
        text-align: left;
        font-weight: 500;
        font-size: 16px;
        color: $color-muddy-waters;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .button {
      margin-top: 30px;
    }
  }
}
