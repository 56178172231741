.drawer {
  .MuiPaper-root {
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  }

  &__top {
    background-color: $color-primary;

    .MuiSvgIcon-root {
      color: $color-muddy-waters;
    }
  }

  .MuiListItemIcon-root {
    min-width: 32px;
  }

  .MuiPaper-root {
    display: flex;
    justify-content: space-between;
  }

  .MuiListItem-button:not(.Mui-selected) {
    .MuiListItemIcon-root {
      opacity: 0.5;
    }
  }

  .MuiList-root {
    flex-grow: 1;

    .Mui-selected {
      background-color: transparent;

      .MuiListItemText-primary {
        font-weight: bold;
      }
    }
  }

  .text-copyright {
    padding: 8px 16px;
    text-align: center;
  }
}
