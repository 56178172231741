.otp-number {
  margin: 20px 0 0;

  > div {
    flex: 1;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  .input-number {
    width: 100% !important;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.12);

    &:focus {
      outline-color: $color-muddy-waters;
    }
  }
}

h3.time-number {
  color: $color-azure-radiance;
  font-size: 20px;
  text-align: center;
}

body {
  .disable-time-count {
    cursor: not-allowed !important;
    &:hover {
      opacity: 1;
    }
    >* {
      color: $color-silver;
    }
  }
}
