.header {
  .menu {
    &-left {
      flex: 1;
    }

    &-right {
      display: flex;
      align-items: center;
    }
  }
}

.button--language {
  margin-left: auto;

  .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: 14px 40px 14px 10px;
    border-radius: 4px;
    color: $color-white;
    font-size: 14px;
    font-weight: 500;
  }

  .flag {
    margin-right: 10px;
  }

  .MuiSelect-icon {
    color: $color-white;
  }

  &:hover {
    cursor: pointer;

    .MuiOutlinedInput-notchedOutline {
      border-color: $color-muddy-waters;
      opacity: 0.5;
    }
  }

  .MuiOutlinedInput-notchedOutline,
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $color-muddy-waters;
    border-width: 1px;
  }
}
